import classNames from 'classnames';
import * as React from 'react';
import { UILibraryComponent } from '../core';
import { UILibraryComponentProps } from '../core/types';

type CardProps = UILibraryComponentProps<{
  /**
   * Whether or not the content should expand to the edges of the Card
   *
   * @default false
   */
  fullBleed?: boolean;

  /**
   * Whether or not the card should have a border
   *
   * @default false
   */
  bordered?: boolean;

  /**
   * Whether or not this Card should stretch its height to fill its parent GridContainer Row
   *
   * This is useful if you have a Row of Cards and you want them to all be the same height
   *
   * @default false
   */
  evenHeight?: boolean;
}>;

export const Card = React.forwardRef<HTMLElement, CardProps>((props, ref) => {
  const {
    fullBleed = false,
    bordered = false,
    evenHeight = false,

    className,
    ...otherProps
  } = props;

  return (
    <UILibraryComponent
      component="div"
      ref={ref}
      className={classNames(
        'card-container',
        { ['card-container-bordered']: bordered },
        { ['shadow-container-full-bleed']: fullBleed },
        { ['card-container-even-height']: evenHeight },
        className
      )}
      {...otherProps}
    />
  );
});

Card.displayName = 'Card';
