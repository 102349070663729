import { graphql, useStaticQuery } from 'gatsby';
import React from 'react';
import Posts from '../../blog/components/Posts';

const BlogPosts = ({ location }) => {
  const data = useStaticQuery(graphql`
    query PostsQueryProper {
      site {
        siteMetadata {
          title
          social {
            name
            url
          }
        }
      }
      allBlogPost(sort: { fields: [date, title], order: DESC }, limit: 1000) {
        edges {
          node {
            id
            excerpt
            slug
            title
            date(formatString: "MMMM DD, YYYY")
            tags
            image {
              childImageSharp {
                fluid {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            imageAlt
          }
        }
      }
    }
  `);

  const { site, allBlogPost } = data;
  return (
    <Posts
      location={location}
      posts={allBlogPost.edges}
      siteTitle={site.siteMetadata.title}
    />
  );
};

export default BlogPosts;
