import { Link } from 'gatsby';
import Image from 'gatsby-image';
import React from 'react';
import { Box } from '../../components/Box';
import { Typography } from '../../components/Typography';
import { PostDate } from './PostDate';
import styles from './PostLink.module.scss';
import { Card } from '../../components/Card';

type PostLinkProps = {
  post: any;
};

export const PostLink: React.FC<PostLinkProps> = ({ post }) => {
  return (
    <Card fullBleed bordered>
      {post.image?.childImageSharp && (
        <Image
          className={styles.postLink__image}
          fluid={post.image.childImageSharp.fluid}
          alt={post.imageAlt ? post.imageAlt : post.excerpt}
        />
      )}
      <Box padding={4}>
        <Link to={post.slug}>
          <Typography component="h2" textSize={4} weight="bold" margin={0}>
            {post.title || post.slug}
          </Typography>
        </Link>
        <PostDate>{post.date}</PostDate>
      </Box>
    </Card>
  );
};
