import React from 'react';
import { Layout } from './Layout';
import { PostList } from './PostList';

const Posts: React.FC<any> = ({ posts }) => (
  <Layout>
    <PostList posts={posts} />
  </Layout>
);

export default Posts;
