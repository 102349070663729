import classNames from 'classnames';
import * as React from 'react';
import { UILibraryComponent } from '../core';
import { UILibraryComponentProps } from '../core/types';

type TextSize = 1 | 2 | 3 | 4 | 5 | 6;
type TextColor =
  | 'standard'
  | 'error'
  | 'alert'
  | 'holiday'
  | 'white'
  | 'dark-grey'
  | 'pink'
  | 'brand-orange';

type TypographyProps = UILibraryComponentProps<{
  /**
   * The color of the Typography
   *
   * @default "standard"
   */
  textColor?: TextColor;

  /**
   * The text size
   **/
  textSize?: TextSize;

  /**
   * The boldness of the text
   *
   * @default "standard"
   */
  weight?: 'standard' | 'bold';

  /**
   * The text-alignment
   *
   * @default "left"
   */
  align?: 'left' | 'center' | 'right';

  /**
   * Whether or not the text should render in ALL CAPS
   *
   * @default false
   */
  allCaps?: boolean;

  /**
   * Whether or not the text should render as Overline.
   *
   * An "overline" is text that goes right above a heading, providing broader-level context for the heading,
   * often to indicate the product category of the product described by the heading.
   *
   * @default false
   */
  overline?: boolean;
}>;

export const Typography = React.forwardRef<HTMLElement, TypographyProps>(
  (props, ref) => {
    const {
      textSize,
      textColor = 'standard',
      allCaps = false,
      overline = false,
      weight = 'standard',
      align = 'left',
      className,
      ...otherProps
    } = props;

    return (
      <UILibraryComponent
        component="div"
        ref={ref}
        className={classNames(
          { [`text-size-${textSize}`]: textSize !== undefined },
          { 'all-caps': allCaps },
          { overline: overline },
          { strong: weight === 'bold' },
          { [`text-${align}`]: align === 'center' || align === 'right' },
          { error: textColor === 'error' },
          { alert: textColor === 'alert' },
          {
            [`text-color-${textColor}`]:
              textColor === 'white' ||
              textColor === 'holiday' ||
              textColor === 'dark-grey' ||
              textColor === 'pink' ||
              textColor === 'brand-orange',
          },
          className
        )}
        {...otherProps}
      />
    );
  }
);

Typography.displayName = 'Typography';
