import React from 'react';
import { PostLink } from './PostLink';
import styles from './PostList.module.scss';

export const PostList = ({ posts }) => (
  <div className={styles.postList}>
    {posts.map(({ node }) => (
      <PostLink key={node.slug} post={node} />
    ))}
  </div>
);
